import Vue, { provide } from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueClipboard from 'vue-clipboard2';
import VueAnime from 'vue-animejs';
import 'virtual:svg-icons-register';
import VueApollo from 'vue-apollo';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { DefaultApolloClient } from '@vue/apollo-composable';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import { addOnUserChange } from './store/auth';
import { createGraphqlClient } from './api/graphql';
import Snackbar from '@/plugins/snackbar/snackbar';
import NotificationService from '@/views/AppLayout/components/NotificationService/NotificationService.vue';
import { api } from '@/api/api';
import stopScroll from '@/utils/stopScroll';
import createRouter from '@/router/createRouter';
import setUpSentry from '@/plugins/sentry';
import { setUpGrowthBook } from '@/plugins/growthbook';

Vue.use(VueClipboard);
Vue.use(VueAnime);
Vue.use(Snackbar, { vuetify });
Vue.use(VueApexCharts);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(VueApollo);

Vue.component('Apexchart', VueApexCharts);

VueClipboard.config.autoSetContainer = true;
Vue.prototype.$notificationService = new NotificationService();
Vue.prototype.$stopScroll = stopScroll;
Vue.prototype.$api = api;
Vue.config.productionTip = false;
api.registerAuthTokens(store);

const apolloClient = createGraphqlClient(() => store.dispatch('auth/getAccessToken'));
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
addOnUserChange((_user) => apolloClient.resetStore());

const routes = createRouter(apolloClient);

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

// Setting up GrowthBook
setUpGrowthBook(addOnUserChange, api);

// Setting up Sentry
if (import.meta.env.MODE !== 'development') {
  setUpSentry(Vue, router, addOnUserChange);
}

store.dispatch('auth/init').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    apolloProvider,
    setup() {
      provide(DefaultApolloClient, apolloClient);
    },
    created() {
      api.registerHandlers(this);
      provide('growthBook', Vue.prototype.$growthBook);
      provide('store', store);
      provide('snackbar', Vue.prototype.$snackbar);
    },
    render: (h) => h(App),
  }).$mount('#app');
});
